<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <PricingAutomationForm
      :key="options.pricing_automation ? options.pricing_automation.id : 0"
      :index="options.index"
      :pricing-automation="options.pricing_automation"
      :sites="options.sites"
      :brands="options.brands"
      :repeat-options="options.repeatOptions"
      :action="action"
      :from="options.from"
    />
  </div>
</template>
  
  <script>
  import PricingAutomationForm from "@/components/forms/Autopricing";
  
  export default {
    components: {
      PricingAutomationForm
    },
    props: {
      options: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        title: "",
        action: ""
      };
    },
    created() {
      if (this.options.action == "read") {
        if (this.$can("update", "catalog.autopricing")) {
          this.action = "update";
          this.title = this.$t("edit_noun");
        } else if (this.$can("read", "catalog.autopricing")) {
          this.action = "read";
          this.title = this.$t("autopricing");
        }
      } else {
        this.action = "create";
        this.title = this.$t("create");
      }
    }
  };
  </script>
  
  