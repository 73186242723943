<template>
  <div v-if="!drawerLoading" class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
      class="wrap"
      tag="div"
    >
      <form
        key="return"
        method="POST"
        novalidate
        @submit.prevent="handleSubmit(returnOrder)"
      >
        <ul class="cart">
          <li v-if="list.length > 1" class="columns">
            <div class="column is-12 is-flex is-justify-content-space-between py-0">
              <b-checkbox 
                v-model="select_all" 
                class="my-2"
                :disabled="selectAllDisabled"
              >
                <span class="ml-2">
                  <b>{{ actions.select_all && actions.select_all.label }}</b>
                </span>
              </b-checkbox>
              <b-select-validation
                v-if="select_all"
                v-model="selectAllReason"
                :placeholder="$t('reason')"
                hide-details
                size="is-small"
              >
                <option 
                  v-for="r in filters.subjects" 
                  :key="r.id" 
                  :value="r.id"
                >
                  {{ r.label }}
                </option>
              </b-select-validation>
            </div>
          </li>
          <li
            v-for="(item, index) in list"
            :key="item.id"
            class="columns is-mobile is-variable is-1 is-flex is-align-items-center"
          >
            <div class="column is-1-tablet is-3-mobile">
              <b-checkbox
                v-model="item.return"
                :disabled="item.actions.select.disabled"
                false-value
                @input="reset(item)"
              />
            </div>
            <div class="column is-2-tablet is-3-mobile py-0">
              <img
                width="80"
                height="80"
                :src="
                  (item.img &&
                    `${item.img.url}${item.img.path}${item.img.filename}-w80h80@2x.${item.img.version}.jpg`) ||
                    '/img/blank.svg'
                "
                :alt="item.name"
              />
            </div>
            <div class="column is-4-tablet is-3-mobile ml-2">
              <div class="is-size-7">
                <b>{{ item.name }}</b>
              </div>
              <div class="has-text-grey is-size-8 mt-1">
                {{ item.details }}
              </div>
            </div>
            <b-select-validation
              v-if="item.actions.quantity"
              v-model="item.returnQty"
              :rules="item.return ? 'required' : ''"
              :placeholder="$t('choose')"
              class="column is-2-tablet is-3-mobile pr-4"
              size="is-small"
            >
              <option 
                v-for="q in qtyArray(item)" 
                :key="q" 
                :value="q"
              >
                {{ q }}
              </option>
            </b-select-validation>
            <b-select-validation
              v-if="item.actions.subject"
              v-model="item.returnReason"
              :rules="item.return ? 'required' : ''"
              :placeholder="$t('reason')"
              :selectExpanded="false"
              class="column is-3-tablet is-3-mobile"
              size="is-small"
              @input="selectReason(index)"
            >
              <option 
                v-for="r in filters.subjects" 
                :key="r.id" 
                :value="r.id"
              >
                {{ r.label }}
              </option>
            </b-select-validation>
            <div
              v-if="item.actions.message"
              class="column is-5-tablet is-3-mobile"
            >
              <b-icon
                icon="alert"
                size="is-small"
                class="mr-1"
                type="is-danger"
              />
              {{ item.actions.message.ticket }}
              <router-link 
                class="ml-1"
                :to="getUrl([item.actions.ticket_id], item.actions.message.link)"
                target="_blank"
              >
                {{ item.actions.ticket_id }}
              </router-link>
              {{ " : " + item.actions.message.status }}
            </div>
          </li>
        </ul>
        <ImageUpload
          class="small-uploader mt-5"
          :label="form.fields && form.fields.attachments.placeholder"
          :allow-multiple="true"
          :images="images"
          :required="false"
          :accepted-file-types="form.fields.attachments.accepted.extensions"
          imagePreviewHeight="39px"
          @updateFiles="files => (images = files)"
        />
        <div class="mt-4">
          <b-button
            outlined
            :disabled="loading"
            :loading="loading"
            type="is-primary"
            size="is-small"
            class="mr-2"
            native-type="submit"
          >
            {{ actions.validate.label }}
          </b-button>
          <b-button 
            outlined 
            :disabled="loading"
            size="is-small" 
            @click="$emit('close')"
          >
            {{actions.cancel.label}}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>

  <b-loading
    v-else
    v-model="drawerLoading"
    :is-full-page="false"
  />
</template>

<script>
import ImageUpload from "@/components/forms/elements/ImageUpload";

export default {
  components: {
    ImageUpload
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    allowMultiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      select_all: false,
      selectAllDisabled: true,
      selectAllReason: null,
      drawerLoading: false,
      loading: false,
      list: [],
      images: [],
      title: "",
      products: [],
      filters: {},
      actions: {},
      form: {},
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" viewBox="0 -960 960 960"  fill="#00000040"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"/></svg>'
    };
  },
  computed: {},
  watch: {
    select_all() {
      this.selectAll();
    },
    selectAllReason(newVal) {
      if (newVal) {
        for (var i = 0; i < this.list.length; i++) {
          this.list[i].returnReason = this.selectAllReason;
        }
      }
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.drawerLoading = true;
      this.$axios
        .get("ecommerce/orders/" + this.options.orderId + "/claim")
        .then(response => {
          this.products = response.data.products;
          this.title = response.data.title;
          this.filters = response.data.filters;
          this.actions = response.data.forms.claim.actions;
          this.form = response.data.forms.claim;
          for (var i = 0; i < this.products.length; i++) {
            this.products[i].qty = this.products[i].qty;
            this.products[i].returnQty = this.products[i].qty;
            this.products[i].return = false;
            this.products[i].returnReason = null;
            this.list.push(this.products[i]);
            if (!this.products[i].actions.select.disabled)
              this.selectAllDisabled = false;
          }
        })
        .catch(e => {
          this.$root.clientError(e);
        })
        .finally(() => {
          this.drawerLoading = false;
        });
    },
    qtyArray(item) {
      var items = [];
      for (var j = 1; j <= item.qty; j++) {
        items.push(j);
      }
      return items;
    },
    reset(item) {
      let index = this.list.findIndex(p => p.id == item.id);
      if (!item.return) {
        this.select_all = false;
        this.list[index].return = false;
      } else this.list[index].return = true;
    },
    selectAll() {
      if (this.select_all) {
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].actions.select.disabled === false) {
            this.list[i].return = true;
          }
        }
      }
    },
    selectReason(index) {
      this.selectAllReason = null;
      this.list[index].return = true;
    },
    returnOrder() {
      this.loading = true;
      const formData = new FormData();

      for (let k = 0; k < this.list.length; k++) {
        if (this.list[k].return === true) {
          if (!this.list[k].returnReason) {
            this.loading = false;
            return;
          }
          formData.append(`item[${k}][id]`, this.list[k].id);
          formData.append(`item[${k}][subject_id]`, this.list[k].returnReason);
          formData.append(`item[${k}][qty]`, this.list[k].returnQty);
        }
      }
      if (this.images) {
        for (let j = 0; j < this.images.length; j++) {
          formData.append(`files[${j}]`, this.images[j]);
        }
      }
      this.$axios({
        method: "post",
        url: "ecommerce/orders/" + this.options.orderId + "/return",
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      })
        .then((res) => {
          this.$root.notify(res.data.message, "is-success");
          this.closeDrawer(1);
        })
        .catch(e => {
          this.$root.clientError(e);
        })

        .finally(() => (this.loading = false));
    }
  }
};
</script>
