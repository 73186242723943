<template>
  <div
    v-if="!loading && $can('read', 'orders')"
    class="wrap"
  >
    <p class="title is-size-4-mobile mb-5">
      {{ $tc("order", 1) }}
      <span class="has-text-grey-light">
        #{{ mpId ? mpId : options.order_id }}
      </span>
    </p>
    <OrderForm
      v-if="details && payments && summary && $can('read', 'orders')"
      :index="options.index"
      :order-id="options.order_id"
      :options="{ details, payments, summary, actions }"
      :incoterms="incoterms"
      :mergeable-with="mergeable_with"
      :mp-id="options.mp_id"
      :payment-received="paymentReceived()"
    />
    <Details
      v-if="addresses && items && summary && $can('read', 'orders')"
      :options="{
        addresses: addresses,
        orderId: options.order_id,
        userId: options.user_id,
        items: items,
        summary: summary,
        carriers: details.carriers,
        sid: sid,
        site_id: site.id,
        warehouse_id: warehouse.id,
        lang_iso: lang.iso,
      }"
      :incoterm="details.incoterm"
      :notes="details.notes"
      :editable="editable"
      :editable-pricing="editablePricing"
      :count="count"
      :warehouses="options.warehouses"
    />
    <b-modal
      v-model="showShippedModal"
      :width="800"
      :height="520"
      custom-class="overflow"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("shipping_info") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <form
            class="columns is-multiline is-mobile"
            autocomplete="off"
            @submit.prevent="handleSubmit(saveShippedStatusInfo)"
          >
            <div
              v-for="(num, i) in editedTrackingNums"
              :key="'shipping' + i"
              class="column is-6"
              :class="editedTrackingNums[i].type == 'return' ? 'is-hidden' : ''"
            >
              <template v-if="editedTrackingNums[i].type !== 'return'">
                <b-field
                  addons
                  class="mb-0"
                >
                  <p class="control">
                    <b-select-validation
                      v-model="editedTrackingNums[i].carrier"
                      :label="$tc('carrier', 1)"
                      :vid="'carrier' + i"
                      :disabled="i == 0 && editedTrackingNums[i].id !== null"
                      :rules="editedTrackingNums[i].tracking_number ? 'required' : ''"
                    >
                      <template>
                        <option
                          v-for="opt in carriers"
                          :key="opt.id"
                          :value="opt.id"
                        >
                          {{ opt.label }}
                        </option>
                      </template>
                    </b-select-validation>
                  </p>
                  <p class="control">
                    <b-input-validation
                      v-model="editedTrackingNums[i].tracking_number"
                      :label="$t('tracking_number')"
                      :placeholder="placeholders.order.tracking_num"
                      hide-details
                      @input="setTracking()"
                    />
                  </p>
                  <p class="control">
                    <b-button
                      type="is-primary"
                      @click.stop="showCalendar(i)"
                    >
                      <b-icon
                        icon="calendar"
                        size="is-small"
                      />
                    </b-button>
                  </p>
                </b-field>

                <div class="dates-dropdown">
                  <div
                    class="menu-ol"
                    :class="{ active: showTrackingCalendar[i] }"
                  >
                    <div
                      v-on-clickaway="away"
                      class="columns is-mobile is-multiline"
                    >
                      <b-field
                        :label="$t('shipped_at')"
                        custom-class="is-medium"
                        class="column is-6-tablet is-6-mobile mt-5"
                      >
                        <b-datepicker-validation
                          v-model="editedTrackingNums[i].shipped_at.datetime"
                          class=""
                          :vid="'shipped_at'"
                          size="is-small"
                          inline
                          locale="fr-CA"
                          format="YYYY-MM-DD"
                          :placeholder="placeholders.date.day3"
                        />
                      </b-field>
                      <b-field
                        :label="$t('delivered_at')"
                        custom-class="is-medium"
                        class="column is-6-tablet is-6-mobile mt-5"
                      >
                        <b-datepicker-validation
                          v-model="editedTrackingNums[i].delivered_at.datetime"
                          class=""
                          :vid="'delivered_at'"
                          size="is-small"
                          inline
                          locale="fr-CA"
                          format="YYYY-MM-DD"
                          :placeholder="placeholders.date.day3"
                        />
                      </b-field>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="returnExist"
              class="column is-12 pt-0"
            >
              <p class="is-size-5">
                <b>{{ $t("return") }}</b>
              </p>
            </div>
            <div
              v-for="(num, i) in editedTrackingNums"
              :key="'return' + i"
              class="column is-6"
              :class="editedTrackingNums[i].type == 'return' ? '' : 'is-hidden'"
            >
              <template v-if="editedTrackingNums[i].type == 'return'">
                <b-field addons>
                  <p class="control">
                    <b-select-validation
                      v-model="editedTrackingNums[i].carrier"
                      :label="$tc('carrier', 1)"
                      vid="carrier"
                      disabled
                      hide-details
                    >
                      <template>
                        <option
                          v-for="opt in carriers"
                          :key="opt.id"
                          :value="opt.id"
                        >
                          {{ opt.label }}
                        </option>
                      </template>
                    </b-select-validation>
                  </p>
                  <p class="control">
                    <b-input-validation
                      v-model="editedTrackingNums[i].tracking_number"
                      :label="$t('tracking_number')"
                      :placeholder="placeholders.order.tracking_num"
                      disabled
                      hide-details
                      @input="setTracking()"
                    />
                  </p>
                  <p class="control">
                    <b-button
                      type="is-primary"
                      @click.stop="showCalendar(i)"
                    >
                      <b-icon
                        icon="calendar"
                        size="is-small"
                      />
                    </b-button>
                  </p>
                </b-field>

                <div class="dates-dropdown">
                  <div
                    class="menu-ol"
                    :class="{ active: showTrackingCalendar[i] }"
                  >
                    <div
                      v-on-clickaway="away"
                      class="columns is-mobile is-multiline"
                    >
                      <b-field
                        :label="$t('shipped_at')"
                        custom-class="is-medium"
                        class="column is-6-tablet is-6-mobile mt-5"
                      >
                        <b-datepicker-validation
                          v-model="editedTrackingNums[i].shipped_at.datetime"
                          class=""
                          :vid="'shipped_at'"
                          size="is-small"
                          inline
                          locale="fr-CA"
                          format="YYYY-MM-DD"
                          :placeholder="placeholders.date.day3"
                        />
                      </b-field>
                      <b-field
                        :label="$t('delivered_at')"
                        custom-class="is-medium"
                        class="column is-6-tablet is-6-mobile mt-5"
                      >
                        <b-datepicker-validation
                          v-model="editedTrackingNums[i].delivered_at.datetime"
                          class=""
                          :vid="'delivered_at'"
                          size="is-small"
                          inline
                          locale="fr-CA"
                          format="YYYY-MM-DD"
                          :placeholder="placeholders.date.day3"
                        />
                      </b-field>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <b-switch
              v-model="notify_customer"
              class="column is-12 pt-0"
            >
              {{ $t("notify_customer") }}
            </b-switch>

            <div class="column is-12">
              <b-button
                class="mr-4"
                type="is-success"
                tag="input"
                :value="$t('validate')"
                :loading="loading"
                :disabled="loading"
                native-type="submit"
              />
              <b-button @click="showShippedModal = false">
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
  </div>

  <b-loading
    v-else
    v-model="loading"
    :is-full-page="false"
  />
</template>

<script>
import OrderForm from "@/components/forms/order/Form";
import Details from "@/components/forms/order/Details";
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway2";

export default {
  components: {
    OrderForm,
    Details,
  },
  mixins: [clickaway],
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      incoterms: [],
      order: {},
      addresses: null,
      details: null,
      sid: null,
      items: [],
      summary: null,
      payments: [],
      site: {},
      lang: {},
      warehouse: {},
      show: false,
      editable: false,
      editablePricing: true,
      mpId: this.options.mp_id,
      mergeable_with: [],
      showShippedModal: false,
      showTrackingCalendar: [false],
      tracking_num: null,
      trackingNums: [],
      editedTrackingNums: [],
      notify_customer: false,
      returnExist: false,
      count: {},
      actions: {},
      carriers: [],
    };
  },
  computed: {
    rerender() {
      return JSON.stringify(this.editable) + this.options.order_id;
    },
  },
  created() {
    if (this.options.order_id !== null && this.$can("read", "orders")) {
      this.getOrder(this.options.order_id);
    }
  },
  mounted() {
    this.$bus.$on("update-order-options", (params) => {
      this.$bus.$emit("update-list", params);
      let status =
        params.details && params.details.statuses.find((s) => s.selected);
      if (status) {
        this.changeEditable(status.id);
        this.sid = status.id;
      };
      if (params.items) this.items = params.items;
      if (params.summary) this.summary = params.summary;
      if (params.details) this.details = params.details;
      if (params.count) this.count = params.count;
    });
    this.$bus.$on("update-order-payments", (params) => {
      this.payments = params.payments;
    });
    this.$bus.$on("update-order-note-icoterm", (obj) => {
      this.details.notes = obj.notes;
      this.details.incoterm = obj.incoterm;
    });
    this.$bus.$on("open-shipped-modal", () => {
      this.editedTrackingNums = JSON.parse(JSON.stringify(this.trackingNums));
      if (
        this.editedTrackingNums.length == 0 ||
        (this.editedTrackingNums.length &&
          this.editedTrackingNums[this.editedTrackingNums.length - 1].tracking_number)
      ) {
        let obj = {
          id: null,
          number: null,
          carrier: null,
          type: "shipping",
          shipped_at: { datetime: new Date() },
          delivered_at: { datetime: null },
        };
        this.editedTrackingNums.push(obj);
      }
      this.showShippedModal = true;
    });
  },
  destroyed() {
    this.$bus.$off("update-order-options");
    this.$bus.$off("update-order-payments");
    this.$bus.$off("update-order-note-icoterm");
  },
  methods: {
    getOrder() {
      this.loading = true;

      this.$axios
        .get("ecommerce/orders/" + this.options.order_id)
        .then((res) => {
          this.addresses = res.data.addresses;
          this.items = res.data.items;
          this.summary = res.data.summary;
          this.details = res.data.details;
          this.carriers = res.data.filters.carriers;
          this.payments = res.data.payments;
          let status = this.details.statuses.filter((s) => s.selected === true);
          this.sid = status[0]["id"];
          this.site = res.data.site;
          this.lang = res.data.lang;
          this.warehouse = res.data.warehouse;
          this.incoterms = res.data.incoterms;
          this.mergeable_with = res.data.mergeable_with;
          this.trackingNums = res.data.details.shippings;
          this.returnExist = this.trackingNums.some(
            (item) => item.type === "return"
          );
          this.showTrackingCalendar = Array(this.trackingNums.length + 1).fill(
            false
          );
          this.count = res.data.count;
          this.actions = Array.isArray(res.data.actions)
            ? {}
            : res.data.actions;
          this.changeEditable(status[0]["id"]);
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    paymentReceived() {
      for (let i = 0; i < this.payments.length; i++) {
        let p = this.payments[i];
        if (p.status == "OK" && p.active) return true;
      }
      return false;
    },
    changeEditable(status_id) {     
      this.editablePricing = this.mpId || ( status_id > 5 && status_id !== 15 && this.paymentReceived()) ? false : true;
      if (([2,3,15].includes(status_id)) && !this.mpId)
        this.editable = true;
      else this.editable = false;
    },
    setTracking() {
      if (this.editedTrackingNums[this.editedTrackingNums.length - 1].tracking_number) {
        let obj = {
          id: null,
          number: null,
          carrier: null,
          type: "shipping",
          shipped_at: { datetime: new Date() },
          delivered_at: { datetime: null },
        };
        this.editedTrackingNums.push(obj);
        this.showTrackingCalendar.push(false);
      }
    },
    showCalendar(i) {
      this.showTrackingCalendar.fill(false);
      this.$set(this.showTrackingCalendar, i, true);
    },
    away() {
      for (let i = 0; i < this.showTrackingCalendar.length; i++) {
        this.$set(this.showTrackingCalendar, i, false);
      }
    },
    saveShippedStatusInfo() {
      this.loading = true;
      let data = {
        shippings: JSON.parse(
          JSON.stringify(this.editedTrackingNums.filter((t) => t.tracking_number))
        ),
        notify_customer: this.notify_customer,
      };
      data.shippings.forEach((item) => {
        if (item.delivered_at && typeof item.delivered_at === "object") {
          item.delivered_at = moment(item.delivered_at.datetime)
            .add(1, "d")
            .toISOString();
        }
      });
      data.shippings.forEach((item) => {
        if (item.shipped_at.datetime == null)
          item.shipped_at.datetime = new Date();
        if (item.shipped_at && typeof item.shipped_at === "object") {
          item.shipped_at = moment(item.shipped_at.datetime)
            .add(1, "d")
            .toISOString();
        }
      });
      this.$axios
        .patch("ecommerce/orders/" + this.options.order_id, data)
        .then((res) => {
          this.details = res.data.details;
          this.actions = res.data.actions;
          this.trackingNums = res.data.details.shippings;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.loading = false;
          this.showShippedModal = false;
        });
    },
  },
};
</script>

