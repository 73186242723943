<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="autopricing columns is-multiline is-mobile is-variable is-2">
        <!-- sites -->
        <div class="column is-6-tablet is-12-mobile mb-4">
          <b-field :label="$t('site')">
            <Treeselect
              v-model="myPricing.sld_id"
              class="is-medium"
              :disabled="disabledInfos"
              :placeholder="$t('choose')"
              open-on-focus
              open-on-click
              :disable-branch-nodes="true"
              :multiple="false"
              :options="formattedSites"
              :normalizer="normalizer"
            >
              <div
                slot="value-label"
                slot-scope="{ node }"
              >
                {{ node.raw.label }}
              </div>
            </Treeselect>
          </b-field>
        </div>

        <!-- discount -->
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myPricing.discount"
            rules="required|numeric|max_value:100"
            :disabled="disabledInfos"
            vid="discount"
            :label="$t('discount')"
            :placeholder="placeholders.voucher.amount"
            suffix="%"
          />
        </div>

        <template v-if="!selectSku">
          <div class="column is-11-tablet is-10-mobile mb-4">
            <b-field :label="$tc('brand', 1) + ' (' + $t('optional') + ')'">
              <b-taginput
                ref="taginputbrands"
                v-model="myPricing.brands"
                rules="required"
                :data="formattedBrands"
                :placeholder="myPricing.brands.length == 0 ? $t('choose') : ''"
                field="label"
                :disabled="disabledInfos"
                :autocomplete="true"
                open-on-focus
                ellipsis
                @add="
                  (obj) =>
                    (formattedBrands = formattedBrands.filter(
                      (s) => s.id != obj.id
                    ))
                "
                @typing="filterBrands"
                @remove="(obj) => formattedBrands.push(obj)"
              >
                <template slot-scope="props">
                  {{ props.option.label }}
                </template>
                <template
                  slot="selected"
                  slot-scope="props"
                >
                  <b-tag
                    v-for="(tag, i) in props.tags"
                    :key="i"
                    type="is-primary"
                    :tabstop="false"
                    closable
                    @close="$refs.taginputbrands.removeTag(i, $event)"
                  >
                    {{ tag.label }}
                  </b-tag>
                </template>
              </b-taginput>
            </b-field>
          </div>
        </template>
        <div
          v-else
          class="column is-11-tablet is-10-mobile"
        >
          <SearchProduct
            v-if="renderSearchProduct"
            class="mb-4"
            :disabled="disabledInfos || !myPricing.sld_id"
            :label="$tc('sku_or_product')"
            :autofocus="true"
            :selected-prod="prodToAdd"
            :exclude="myPricing.skus.reduce((arr, obj) => [...arr, obj.id], [])"
            :warehouse-id="1"
            :hide-details="true"
            :sites-ids="getSiteIdById(myPricing.sld_id)"
            :sld-id="Math.abs(parseInt(myPricing.sld_id))"
            @selectItem="
              (p) => {
                selectProd(p);
              }
            "
          />
        </div>

        <div class="column is-1-tablet is-2-mobile">
          <b-button
            @click="
              selectSku = !selectSku;
              resetBrandsProducts();
            "
          >
            <b-icon
              icon="swap-horizontal"
              type="is-grey"
              custom-size="mdi-18px"
            />
          </b-button>
        </div>

        <div
          v-if="myPricing.skus.length && selectSku"
          class="column is-12-tablet is-12-mobile mb-4"
        >
          <div class="prods bg-cream">
            <b class="mr-4">{{ $tc("product", 2) }}:</b>
            <ul
              v-for="(sku, j) in myPricing.skus"
              :key="'prod' + j"
              class="mr-4 mb-1"
            >
              <b-tag
                closable
                size="is-default"
                type="is-primary"
                aria-close-label="deleteProduct"
                @close="deleteSku(j)"
              >
                {{ sku.label }}
              </b-tag>
            </ul>
          </div>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myPricing.starts_at"
            :label="$t('starts_at')"
            vid="starts_at"
            :disabled="disabledInfos"
            :min-date="min_date"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
            :increment-minutes="60"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myPricing.ends_at"
            :label="$t('ends_at')"
            vid="ends_at"
            :min-date="myPricing.starts_at"
            :disabled="disabledInfos"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
            :increment-minutes="60"
          />
        </div>

        <!-- repeat -->
        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myPricing.repeat"
            rules="required"
            :disabled="disabledInfos"
            vid="repeat"
            :label="$t('repeat')"
          >
            <template>
              <option
                v-for="opt in repeatOptions"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div
          v-if="!$device.mobile || myPricing.repeat != 'never'"
          class="column is-6-tablet is-12-mobile"
        >
          <b-datepicker-validation
            v-if="myPricing.repeat != 'never'"
            v-model="myPricing.expires_at"
            :label="$t('expire_at')"
            rules="required"
            vid="expire_at"
            :disabled="disabledInfos"
            :min-date="myPricing.ends_at"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
            :increment-minutes="60"
          />
        </div>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="save_loading || disabledInfos"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
  
  <script>
import moment from "moment";
import SearchProduct from "@/components/forms/elements/searchProduct.vue";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    SearchProduct,
    Treeselect,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    pricingAutomation: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      default: "cu", // cu : create+update
    },
    from: {
      type: String,
      default: null,
    },
    sites: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    repeatOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      save_loading: false,
      disabledInfos: false,
      selectSku: false,
      menu: false,
      prodToAdd: "",
      renderSearchProduct: true,
      timePickerProps: {
        incrementMinutes: 60,
      },
      myPricing: {
        sld_id: [],
        brands: [],
        skus: [],
        discount: null,
        starts_at: "",
        ends_at: "",
        expires_at: "",
        repeat: "never",
      },
      formattedSites: [],
      formattedBrands: [],
    };
  },
  computed: {
    min_date() {
      return moment().format("YYYY-MM-DD");
    },
    method() {
      if (this.index === -1) return "post";
      else return "put";
    },
  },
  watch: {},
  created() {
    this.formattedBrands = this.brands;

    if (this.action == "read") {
      this.disabledInfos = true;
    }

    this.myPricing.sld_id = this.pricingAutomation.sld_id
      ? this.pricingAutomation.sld_id * -1
      : null;

    this.selectSku =
      this.pricingAutomation.skus && this.pricingAutomation.skus.length;

    let selectedBrands = [];
    for (const b of this.formattedBrands) {
      if (
        this.pricingAutomation.brands &&
        this.pricingAutomation.brands.length > 0 &&
        this.pricingAutomation.brands.map((b) => b.id).includes(b.id)
      )
        selectedBrands.push(b);
    }

    this.myPricing.brands = selectedBrands;

    this.myPricing.discount = this.pricingAutomation.discount
      ? this.pricingAutomation.discount.value
      : null;

    this.myPricing.repeat = this.pricingAutomation.repeat
      ? this.pricingAutomation.repeat.id
      : "never";

    this.myPricing.skus = this.pricingAutomation.skus
      ? this.pricingAutomation.skus
      : [];

    if (this.pricingAutomation.ends_at) {
      let d = moment.parseZone(this.pricingAutomation.ends_at.datetime);
      this.myPricing.ends_at = d.format("YYYY-MM-DD HH:mm");
    }
    if (this.pricingAutomation.expires_at) {
      let d = moment.parseZone(this.pricingAutomation.expires_at.datetime);
      this.myPricing.expires_at = d.format("YYYY-MM-DD HH:mm");
    }
    if (this.pricingAutomation.starts_at) {
      let d = moment.parseZone(this.pricingAutomation.starts_at.datetime);
      this.myPricing.starts_at = d.format("YYYY-MM-DD HH:mm");
    }
  },
  mounted() {
    this.formattedSites = this.setSites(this.sites);
    if (!this.pricingAutomation.ends_at)
      this.myPricing.ends_at = moment()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm");
    if (!this.pricingAutomation.starts_at)
      this.myPricing.starts_at =
        moment().minute() === 0
          ? moment().format("YYYY-MM-DD HH:mm")
          : moment().add(1, "hour").startOf("hour").format("YYYY-MM-DD HH:mm");
  },
  methods: {
    selectProd(p) {
      if (p.id) {
        this.renderSearchProduct = false;
        this.blurFocusedElement();
        this.myPricing.skus.push({ id: p.id, label: p.label });
        this.prodToAdd = "";
        setTimeout(() => {
          this.renderSearchProduct = true;
        }, 10);
      }
    },
    deleteSku(j) {
      this.myPricing.skus.splice(j, 1);
    },
    resetBrandsProducts() {
      this.blurFocusedElement();
      this.myPricing.brands = [];
      this.myPricing.skus = [];
    },
    blurFocusedElement() {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    },
    filterBrands(search) {
      this.formattedBrands = this.brands.filter((option) => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    // setting data for compoenents props
    setSites(sites) {
      let arr = [];
      for (let i = 0; i < sites.length; i++) {
        const site = sites[i];
        let obj = {
          name: site.label,
          label: site.label,
          id: site.id,
          children: [],
        };
        for (let j = 0; j < site.tlds.length; j++) {
          const tld = site.tlds[j];
          obj.children.push({
            name: tld.country.label,
            id: `-${tld.sld_id}`,
            site_id: site.id,
            label: `${site.label}-${tld.country.label}`,
          });
        }
        arr.push(obj);
      }
      return arr;
    },
    getSiteIdById(id) {
      if (id) {
        for (const item of this.formattedSites) {
          for (const child of item.children) {
            if (child.id === id.toString()) {
              return [child.site_id];
            }
          }
        }
      }
      return [];
    },
    save() {
      this.save_loading = true;
      let brands = this.myPricing.brands.map((b) => b.id);

      let id = this.index !== -1 ? "/" + this.pricingAutomation.id : "";
      let data = {
        discount: this.myPricing.discount,
        brands: brands,
        skus: this.myPricing.skus
          .filter((item) => item.id !== null)
          .map((item) => item.id),
        sld_id: Math.abs(parseInt(this.myPricing.sld_id)),
        starts_at: this.myPricing.starts_at,
        ends_at: this.myPricing.ends_at,
        expires_at:
          this.myPricing.repeat == "never" ? null : this.myPricing.expires_at,
        repeat: this.myPricing.repeat,
      };
      this.$axios({
        method: this.method,
        url: "ecommerce/autopricing" + id,
        data: data,
      })
        .then((res) => {
          this.$bus.$emit("save-princing-automation", {
            index: this.index,
            pricing_automation: res.data[0],
          });
          this.closeDrawer("main");
        })
        .catch((e) => {
          this.clientError(e);
        })
        .finally(() => (this.save_loading = false));
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },
  },
};
</script>
  
  