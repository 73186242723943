<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myVoucher.site_id"
            rules="required"
            vid="site"
            :disabled="
              disabledInfos || action == 'update' || from === 'profile'
            "
            :label="$t('site')"
          >
            <template>
              <option
                v-for="opt in sites"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
                <template v-if="voucher.user">
                  ({{ voucher.user.name }})
                </template>
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myVoucher.voucher"
            :rules="
              index === -1
                ? `required|valid_voucher:${myVoucher.site_id}`
                : 'required'
            "
            validation-mode="lazy"
            :disabled="disabledInfos || action == 'update'"
            vid="voucher"
            :label="$tc('voucher', 1)"
            :placeholder="placeholders.voucher.code"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-autocomplete-validation
            v-model="myVoucher.brand_id"
            :expanded="true"
            :label="$tc('brand', 1) + ' (' + $t('optional') + ')'"
            :data="brands"
            :placeholder="$t('choose')"
            vid="brand_id"
            :disabled="disabledInfos"
            item-text="label"
            field="id"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-autocomplete-validation
            v-model="myVoucher.category_id"
            :expanded="true"
            :label="$tc('category', 1) + ' (' + $t('optional') + ')'"
            :data="formattedCategories"
            :placeholder="$t('choose')"
            vid="category_id"
            :disabled="disabledInfos"
            item-text="label"
            field="id"
            type="category"
          />
        </div>

        <div class="column is-3-tablet is-6-mobile">
          <b-select-validation
            v-model="myVoucher.type"
            rules="required"
            vid="type"
            :disabled="disabledInfos || action == 'update'"
            :label="$t('type')"
          >
            <template>
              <option
                v-for="opt in voucher_types"
                :key="opt.type"
                :value="opt.type"
              >
                {{ opt.name }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-3-tablet is-6-mobile">
          <b-input-validation
            v-model="myVoucher.amount"
            :rules="
              myVoucher.type === 'shipping'
                ? ''
                : 'required|numeric|max_value:100'
            "
            :disabled="disabledInfos || myVoucher.type == 'shipping'"
            vid="amount"
            :label="$t('percentage')"
            :placeholder="placeholders.voucher.amount"
            suffix="%"
          />
        </div>

        <div class="column is-3-tablet is-6-mobile">
          <b-input-validation
            v-model="myVoucher.min_amount"
            rules="numeric"
            :disabled="disabledInfos"
            vid="min_amount"
            :label="$t('min_amount') + ' (' + $t('optional') + ')'"
            :placeholder="placeholders.voucher.min_amount"
          />
        </div>

        <div class="column is-3-tablet is-6-mobile">
          <b-select-validation
            v-model="myVoucher.currency_code"
            :rules="myVoucher.min_amount ? 'required' : ''"
            vid="currency_code"
            :disabled="disabledInfos"
            :label="$t('currency_code') + (!myVoucher.min_amount ? ' (' + $t('optional') + ')' : '')"
          >
            <template>
              <option
                v-for="opt in currencies"
                :key="opt.code"
                :value="opt.code"
              >
                {{ opt.code }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myVoucher.starts_at"
            :label="$t('starts_at')"
            vid="starts_at"
            :disabled="disabledInfos"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myVoucher.ends_at"
            :label="$t('expire_at')"
            rules="required"
            vid="ends_at"
            :disabled="disabledInfos"
            :min-date="min_date"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
          />
        </div>

        <div class="column is-4">
          <b-switch
            v-model="myVoucher.use_once"
            :disabled="disabledInfos"
          >
            {{
              $t("use_once")
            }}
          </b-switch>
        </div>

        <div class="column is-4">
          <b-switch
            v-model="myVoucher.highlight"
            :disabled="disabledInfos"
          >
            {{
              $t("highlight")
            }}
          </b-switch>
        </div>

        <div class="is-flex column is-4">
          <b-switch
            v-model="myVoucher.combo"
            :disabled="disabledInfos"
          >
            {{
              $t("combo")
            }}
          </b-switch>
          <b-tooltip
            class="mt-1"
            :label="$t('combo_description')"
            position="is-left"
            multilined
          >            
            <b-icon
              icon="information-variant-circle-outline"
              type="is-info"
              size="is-small"
            />
          </b-tooltip>
        </div>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="save_loading || disabledInfos"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import moment from "moment";

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    defaultSiteId: {
      type: Number,
      default: null
    },
    voucher: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      default: null
    },
    action: {
      type: String,
      default: "cu" // cu : create+update
    },
    from: {
      type: String,
      default: null
    },
    brands: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      save_loading: false,
      disabledInfos: false,
      voucher_types: [
        { type: "percentage", name: this.$i18n.t("percentage") },
        { type: "shipping", name: this.$i18n.t("free_shipping") }
      ],
      menu: false,
      myVoucher: {
        voucher: "",
        site_id: null,
        type: "",
        amount: null,
        min_amount: null,
        currency_code: "",
        use_once: false,
        ends_at: "",
        starts_at: "",
        highlight: false,
        combo: false,
      },
      site_id: 0,
      formattedCategories: []
    };
  },
  computed: {
    min_date() {
      return moment().format("YYYY-MM-DD");
    },
    method() {
      if (this.index === -1) return "post";
      else return "put";
    },
    currencies() {
      let site = this.sites.find(element => element.id == this.site_id);
      return site ? site.currencies : [];
    }
  },
  watch: {
    "myVoucher.site_id": function(new_value) {
      this.site_id = new_value;
    }
  },
  created() {
    this.formattedCategories = this.categories.reduce((arr, c) => {
      if (c.children) {
        let level2 = c.children.reduce((arr2, c2) => {
          if (c2.children) {
            let level3 = c2.children.reduce((arr3, c3) => {
              return [...arr3, { itemClass: "ml-5", ...c3 }];
            }, []);
            return [
              ...arr2,
              { itemClass: "has-text-weight-semibold ml-3", ...c2 },
              ...level3
            ];
          }
          return [
            ...arr2,
            { itemClass: "has-text-weight-semibold ml-3", ...c2 }
          ];
        }, []);
        return [...arr, { itemClass: "has-text-weight-bold", ...c }, ...level2];
      }
      return [...arr, { itemClass: "has-text-weight-bold", ...c }];
    }, []);

    if (this.action == "read") {
      this.disabledInfos = true;
    }
    if (this.voucher.site_id !== undefined) this.site_id = this.voucher.site_id;

    this.myVoucher.voucher = this.voucher.voucher;
    this.myVoucher.brand_id = this.voucher.brand_id || this.voucher.brand.id;
    this.myVoucher.category_id = this.voucher.category_id || this.voucher.category.id;
    this.myVoucher.highlight = this.voucher.highlight;
    this.myVoucher.type = this.voucher.type ? this.voucher.type : "percentage";
    this.myVoucher.site_id = this.voucher.site_id;
    this.myVoucher.amount = this.voucher.amount
      ? this.voucher.amount.value
      : null;
    this.myVoucher.min_amount = this.voucher.min_amount
      ? this.voucher.min_amount.value
      : null;
    this.myVoucher.currency_code = this.voucher.currency
      ? this.voucher.currency.code
      : null;
    this.myVoucher.use_once = this.voucher.use_once
      ? this.voucher.use_once
      : false;
    this.myVoucher.combo = this.voucher.combo
      ? this.voucher.combo
      : false;
    if (this.voucher.ends_at) {
      let d = moment.parseZone(this.voucher.ends_at.datetime);
      this.myVoucher.ends_at = d.format("YYYY-MM-DD HH:mm");
    }
    if (this.voucher.starts_at) {
      let d = moment.parseZone(this.voucher.starts_at.datetime);
      this.myVoucher.starts_at = d.format("YYYY-MM-DD HH:mm");
    }
  },
  mounted() {
    if (!this.voucher.ends_at)
      this.myVoucher.ends_at =
        moment()
          .add(1, "M")
          .format("YYYY-MM-DD") + " 00:00";
    if (!this.voucher.starts_at)
      this.myVoucher.starts_at =
        moment()
          .format("YYYY-MM-DD HH:mm");
    if (this.defaultSiteId) this.myVoucher.site_id = this.defaultSiteId;
  },
  methods: {
    save() {
      if (this.$can("update", "orders.vouchers")) {
        this.save_loading = true;
        let voucher_id = this.voucher.id ? "/" + this.voucher.id : "";
        if (this.userId) this.myVoucher.user_id = this.userId;
        this.$axios({
          method: this.method,
          url: "ecommerce/vouchers" + voucher_id,
          data: this.myVoucher
        })
          .then(res => {
            this.$bus.$emit("save-voucher", {
              index: this.index,
              voucher: res.data
            });
            this.closeDrawer("main");
          })
          .catch(e => {
            this.clientError(e);
          })
          .finally(() => (this.save_loading = false));
      }
    }
  }
};
</script>

